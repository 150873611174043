<template>
  <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
  <div class="rounded overflow-hidden" :style="'width:100%'">
    <div>
      <!-- header -->
      <!-- <div class="px-3 text-center text-overflow-1 w-100 d-flex justify-content-between"
                 style="line-height: 24px;font-size: 12px"> -->
      <!--                {{resData.odds_percent}}-->
      <!-- <div class="text-overflow-1" style="font-size: 12px">{{resData.box_record.name}}</div>
                <div class="text-overflow-1">{{resData.box_record.dura_alias}}</div>
            </div> -->

      <div
        class="cover-cover"
        style="height: 140px; width: 100%; background-size: 100% 100%"
      >
        <div>
          <img class="qiang-img" v-lazy="resData.box_record.cover" alt="" />
        </div>
      </div>
      <div class="price">
        <div>{{ resData.bean }}</div>
      </div>
      <!-- <div class="w-100 d-none">
                <div class="text-overflow-1" style="font-size: 12px">{{resData.box_record.name}}</div>
            </div> -->
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "weapon",
  data(){
    return {
      priceSum:0
    }
  },
  props: {
    width: {
      type: String,
      default: "14%",
    },
    heightWeapon: {
      type: String,
      default: "150px",
    },
    bgSize: {
      type: Object,
      default() {
        return {
          height: "102px",
        };
      },
    },
    weaponSize: {
      type: Object,
      default() {
        return {
          width: "115px",
          marginTop: "25px",
        };
      },
    },
    resData: {
      type: Object,
      default() {
        return {};
      },
    }
  },
   
  mounted(){ 
    // for (let i = 0; i < this.resData.length; i++) {
    // this.priceSum = this.priceSum + (resData.bean - 0)
    // }      
   // this.priceSum=this.priceSum+(this.resData.bean-0)
    //  console.log(this.resData.bean-0);
    //console.log(this.priceSum);
  },
};
</script>

<style scoped lang="scss">
.cover-cover {
  height: auto !important;
  padding-top: 20px;
  padding-left: 20px;
  & img {
    transition: all 0.5s;
    width: 115px;
    height: 85px;
  }
  &:hover img {
    transform: translateY(-20px);
  }
}
.weapon {
  &-name {
    padding: 10px 0;
  }
}
.price {
      width: 100px;
    height: 35px;
    margin-left: 18%;
    margin-top: 8px;
    text-align: center;
    border: 2px solid #0e78c2;
    border-radius: 25px;
    line-height: 30px;
    box-shadow: 0px 0px 0px 2px #0e78c2 inset;
    background-color: #053879;
    color: #039ff8;
}
.qiang-img{
  width: 100px !important;
    height: 72px !important;
    margin: 5px 5px;
}
</style>
