<template>
  <div class="mt-5 content">
    <!-- title -->
    <div>
      <div class="family roll-title">Roll房</div>
    </div>
    <div>
      <div class="f_ROLL_left">
        <!-- 房间左边 -->
        <div>
          <div class="rollHandImg headimgbg">
            <img
              src="../../assets/image/userCenter/user_profile.png"
              alt=""
              width="70px"
              height="70px"
            />
          </div>
          <div
            class="mb-3 mt-1 family roll-left-text"
            style="font-size: 20px; text-align: center"
          >
            官方
          </div>
          <div style="color: #7bb470; font-size: 12px; margin-left: 10px">
            <p style="width: 80px">{{ details.name }}</p>
          </div>
        </div>
        <!-- 房间中间部分 -->
        <!-- <div style="font-size: 10px; width: 120px">
          <div style="margin: -30px 0 20px">
            <p style="color: #009fff">房间限制</p>
            <p>报名参与需要房间口令</p>
          </div>
          <div style="margin: 0 0 -28px">
            <p style="color: #009fff">房间简介</p>
            <p>官方码下级用户本月充值5000即可参与</p>
          </div>
        </div> -->
        <div
          class="ROLL-user d-flex align-items-center justify-content-center joinbnt"
        >
          <div>
            <!-- 请登录 -->
            <div
              v-if="token == undefined && details.status != 1"
              class="family"
              @click="AddRollHome"
            >
              请登录
            </div>
            <div
              v-if="
                token != undefined && details.status != 1 && addRollHomeJR()
              "
              class="family"
              @click="AddRollHome"
            >
              加入房间
            </div>
            <div
              v-if="details.status != 1 && !addRollHomeJR()"
              class="family jiaur"
              @click="AddRollHome"
            >
              已加入
            </div>
            <div
              v-if="details.status == 1"
              class="family jiaur"
              @click="AddRollHome"
            >
              已完成
            </div>
          </div>
        </div>
        <!-- 截止时间 -->
        <div
          v-if="!carry_off_prize"
          class="col-md-4 my-md-0 my-2 text-center"
          style="color: #7ab844; font-size: 14px; padding-top: 15px"
        >
          <span>截止时间</span>
          <span>
            {{
              details.end_time
                ? details.end_time.slice(10, details.end_time.length)
                : "00:00:00"
            }}
          </span>
          <span>
            {{ details.end_time ? details.end_time.slice(0, 10) : "00:00:00" }}
          </span>
        </div>
      </div>
    </div>
    <!-- 开奖按钮 -->
    <!-- <div
      class="py-2 w-25 mx-auto mb-3"
      v-if="details.is_pwd == 1"
      style="border: 1px solid rgb(67, 132, 83); border-radius: 5px"
    >
      <input
        style="width: 360px"
        class="text-white pl-3"
        type="password"
        v-model="pass"
        placeholder="请输入密码"
      />
    </div> -->

    <!-- 中奖者 -->
    <div v-if="carry_off_prize || details.status == 1" class="row">
      <div
        class="text-center font-weight-bold w-100"
        style="margin: 60px 0; font-size: 30px"
      >
        获奖名单
      </div>
      <div
        class="col-6"
        style="margin-bottom: 20px"
        v-for="(item, index) in details.awards"
        :key="index"
      >
        <div
          class="text-center d-flex flex-column justify-content-around align-items-center bg-size-100"
          style="width: 100%; height: 250px"
          :style="`background-image: url(${require(`@/assets/img/f_new/ROLL_Z_J-1.png`)})`"
        >
          <div class="h6" v-if="details.user_id != item.box_record.user.id">
            {{ item.box_record.user.name }}
          </div>
          <div
            style="width: 114px; height: 114px"
            class="bg-size bg-repeat cursor-pointer position-relative"
            :style="`background-image: url(${require('@/assets/img/f_new/ROLL_users_2.png')})`"
          >
            <img
              width="100%"
              class="ROLL-users-user-img img-pos"
              :src="item.box_record.cover"
              alt="枪"
            />
            <img
              class="position-absolute ROLL-users-user-user ROLL-users-user-img"
              width="70px"
              height="70px"
              v-if="details.user_id != item.box_record.user.id"
              :src="item.box_record.user.avatar"
              alt="头象"
            />
          </div>
          <div class="mb-2" style="font-size: 16px">
            {{ item.box_record.name }}
          </div>
          <div style="font-size: 14px">
            {{ item.box_record.dura_alias }}
          </div>
          <div class="" style="font-size: 12px">
            价值：<money />{{ item.bean }}
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex jackpot">
      <div class="title family jfont">奖池奖品</div>
      <div>
        <img src="../../assets/imgV2/roll/diamond.png" class="zuanshi" />
        剩余<span class="totalactive">{{ details.awards.length }}</span
        >件,价值
        <span class="totalactive">{{ priceSum }}</span>
      </div>
    </div>
    <div class="d-flex">
      <div class="d-flex flex-wrap cov">
        <div
          class="list-box"
          v-for="(item, index) in details.awards"
          :key="index"
          style="width: 150px; height: 100px"
        >
          <div style="width: 95%">
            <weapon :res-data="item"></weapon>
          </div>
        </div>
      </div>
    </div>

    <!-- 参与人数 -->
    <div
      class="text-white title font-weight-bold position-relative d-flex usenum"
    >
      <div class="family jfont">参与用户</div>
      <div class="total">
        共计<span class="totalactive">{{ details.users.length }}</span> 人
      </div>
    </div>
    <div class="title">
      <div
        class="peopleimg"
        v-for="(item, index) in details.users"
        :key="index"
      >
        <div class="peopleimg">
          <div class="headimgbg" style="width: 50px; height: 50px">
            <img
              width="100"
              class="ROLL-users-user-img avatar"
              :src="item.user.avatar"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $api from "@/api/rollHome/index";
import weapon from "./common/weapon";
import { getToken } from "@/utils/auth";
export default {
  name: "active",
  components: {
    weapon,
  },
  data() {
    return {
      id: "",
      details: {},
      state: "pond",
      pass: "",
      roll: "",
      dataFUQ: 0,
      priceSum: 0,
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  async mounted() {
    let roll = this.$route.query.roll;
    this.roll = roll;
    this.init();
  },
  beforeUpdate() {
    // console.log(this.details.awards);
    for (let i = 0; i < this.details.awards.length; i++) {
      this.priceSum += this.details.awards[i].bean - 0;
    }
    this.priceSum = Math.floor(this.priceSum * 100) / 100;
  },
  computed: {
    token() {
      return getToken();
    },
    carry_off_prize() {
      return (
        this.details.status == 1 && this.state != "pond" && this.state != "num"
      );
    },
  },
  methods: {
    async init() {
      await this.getRoomsDetail();
    },
    // 立即加入按钮
    async AddRollHome() {
      try {
        let { message, code } = await $api.getRoomsJoin({
          id: this.id,
          password: this.pass,
        });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            message: message,
            type: "success",
            position: "top-left",
          });
        }
        this.init();
        this.pass = "";
      } catch (e) {
        this.$store.commit("LOGIN_TOP", true);
      }
    },
    async getRoomsDetail() {
      try {
        let { data, code, timestamp } = await $api.getRoomsDetail(this.id);
        if (code == 500) return;
        this.dataFUQ = timestamp * 1000;
        if (Date.parse(data.end_time) / 1000 - timestamp < 0) {
          data.status = 1;
        }
        this.details = data;
      } catch (e) {
        console.log(e);
      }
    },
    tabClick(state) {
      this.state = state;
      this.getRoomsDetail();
    },

    addRollHomeJR() {
      let userId = this.$store.state.user_info;
      let user = this.details.users.findIndex((item) => {
        return item.user_id == userId.id;
      });
      return user === -1 ? true : false;
    },
  },
};
</script>

<style scoped lang="scss">
.f_ROLL_left {
  height: 300px;
  padding: 30px 10px 0;
  background-image: url("../../assets/imgV2/roll/roll-bg.png");
  background-size: cover;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: space-around;
  flex-wrap: wrap;
}
.f_ROLL_right {
  clip-path: polygon(14% 0, 100% 0, 100% 100%, 0% 100%);
}
@media (max-width: 1200px) {
  .f_ROLL_left,
  .f_ROLL_right {
    clip-path: initial;
  }
}

.f-Registered-user {
  width: 20%;
  @media (max-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 1366px) {
    width: 30%;
  }
  @media (max-width: 960px) {
    width: 50%;
  }
}
.list-box {
  background-image: url("../../assets/imgV2/roll/gunsbg.png");
  background-size: 102%;
  margin-bottom: 50px;
  width: calc(100% / 5);
  @media (max-width: 1600px) {
    width: calc(100% / 5);
  }
  @media (max-width: 1200px) {
    width: calc(100% / 3);
  }
  @media (max-width: 960px) {
    width: calc(100% / 2);
  }
}
.ROLL-gun {
  width: 80px;
  height: 80px;
  margin-left: 14%;
}
.ROLL-users {
  &-user {
    &-img {
      border-radius: 25px;
      width: 50px;
      height: 50px;
    }
  }
  &-conter {
    width: 9.9%;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    background-image: linear-gradient(
      to right,
      rgba(233, 77, 139, 0.08),
      transparent 30%,
      transparent 70%,
      rgba(233, 77, 139, 0.08)
    );
    border-top: 1px solid rgb(233, 77, 139);
    border-bottom: 1px solid rgb(233, 77, 139);
    border-image: initial;
    border-left: none;
    border-right: none;
    @media (max-width: 1000px) {
      width: 48%;
    }
    &::before {
      top: -1px;
      border-width: 7px 8px 0 8px;
      border-color: #e94d8b transparent transparent transparent;
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      z-index: 4;
      border-style: solid;
    }
    &::after {
      bottom: -1px;
      border-width: 0 8px 7px 8px;
      border-color: transparent transparent #e94d8b transparent;
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      z-index: 4;
      border-style: solid;
    }
  }
}
.ROLL-user {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.content {
  width: 80%;
  margin: 0 auto;
  &-top {
    width: 100%;
  }
  @media (max-width: 1366px) {
    width: 95%;
  }
}
.weapon-phone {
  width: 10%;
  @media (max-width: 1600px) {
    width: 20%;
  }
  @media (max-width: 1366px) {
    width: 25%;
  }
  @media (max-width: 960px) {
    width: 33.33%;
  }
  @media (max-width: 760px) {
    width: 50%;
  }
}
.roll-title {
  font-size: 35px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 2px #98d462;
  text-align: center;
}
.jackpot {
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}
.peopleimg {
  display: inline-block;
  margin: 0px 5px 5px;
}
.usenum {
  justify-content: space-between !important;
  font-size: 20px;
  margin: 20px 0;
}
.joinbnt {
  background-image: url("../../assets/imgV2/roll/btn.png");
  background-size: cover;
  font-size: 16px;
}
.cov {
  justify-content: space-between;
  padding: 0 20px;
  font-size: 16px;
}
.rollHandImg {
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}
.roll-left-text {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px white;
}
.textstyle {
  font-size: 20px;
  color: rgb(244, 242, 242);
  -webkit-text-stroke: 1px rgb(0, 0, 0);
}
.headimgbg {
  background-color: #d8d8d8;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 3px #9fda35 inset;
}
.jfont {
  font-size: 27px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 0.8px #9fda35;
  margin: 20px 0;
}
.zuanshi {
  width: 16px;
}
.total {
  position: absolute;
  top: 40%;
  right: 5%;
  font-size: 16px;
  font-weight: normal;
}
.title {
  padding: 0 20px;
}
.totalactive {
  color: #9fda35;
}
.avatar {
  border: #98d462 solid 2px;
}
.img-pos {
  width: 100px;
  margin-top: 32px;
}
.jiaur {
  color: #fff;
  -webkit-text-stroke: 1px black;
}
</style>
